.success-alert-container {
  width: 200px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(207, 219, 227, 0.26);
  transition: transform ease-in 0.1s;
}

.delete-icon {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
  top: 90%;
  margin-top: 32px;
}

.delete-icon-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.delete-title-text {
  font-size: 1.625em;
  font-family: 'Lato';
  font-weight: 400;
  color: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.delete-sub-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-sub-title-text {
  font-size: 1.125em;
  font-weight: 400;
  color: rgba(108,104,132,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 470px;
  text-align: center;
}

.del-model-bottom-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  padding-left: 10px;
  justify-content: center;
}
.action-buttons-container {
  margin-top: 50px;
  margin-left: 10px;
}

.alert-box-container {
  top: 55px;
  right: 20px;
  width: 350px;
  height: 46px;
  z-index: 1000;
  position: absolute;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 10px rgb(197 197 242/ 0.4);
}

.alert-box-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.alert-box-left-box {
  width: 10px;
  height: 46px;
  background-color: #4CAF50;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.successfully-created-text {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #4CAF50;
  margin-left: 20px;
}

.alert-close-icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
}

.alert-close-icon:hover {
  cursor: pointer;
}
