@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");


@layer base {
  html {
    font-family: Inter;
  }
}

* {
  font-family: "Inter", sans-serif;
}

.ant-picker-active-bar {
  width: 0 !important;
}

.ant-btn {
  background-color: #6D71F9;
}

.ant-picker-range-separator {
  margin-right: 16px;
}


.editor h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.editor h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.editor h3 { 
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.editor h4 { 
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.editor h5 { 
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.editor h6 { 
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.editor ul { 
  list-style-type: disc; 
  list-style-position: inside; 
}
.editor ol { 
  list-style-type: decimal; 
  list-style-position: inside; 
}
.editor ul ul, ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 15px; 
}
.editor ol ol, ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}